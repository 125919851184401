import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function HomePage() {
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  // Example usage:
  const randomString = generateRandomString(10);
  console.log(randomString);

  const navigate = useNavigate();
  useEffect(() => {
    const key = generateRandomString(12);
    navigate(`/boards/${key}`);
  });
  return <div></div>;
}

export default HomePage;
