import "./index.css";
import "font-awesome/css/font-awesome.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BoardsPage from "./pages/Boards";
import HomePage from "./pages/Home";
import React from "react";
import ReactDOM from "react-dom/client";

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <HomePage /> },
    { path: "/boards", element: <HomePage /> },
    { path: "/boards/:key", element: <BoardsPage /> },
  ]);

  return <RouterProvider router={router} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
