import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { Github } from "@uiw/react-color";

const material_palette_300 = [
  "#E57373", //red
  "#F06292", // pink
  "#BA68C8", // purple
  "#9575CD", // deep purple
  "#7986CB", // indigo
  "#64B5F6", // blue
  "#4FC3F7", // light blue
  "#4DD0E1", // cyan
  "#4DB6AC", // teal
  "#81C784", // green
  "#AED581", // light green
  "#DCE775", // lime
  "#FFF176", // yellow
  "#FFD54F", // amber
  "#FFB74D", // orange
  "#FF8A65", // deep orange
  "#A1887F", // brown
  "#989898", // dark grey
  "#b8b8b8", // dark grey
  "#d8d8d8", // light grey
];

const defaultData = {
  tasks: {},
  columns: {
    0: {
      id: 0,
      title: "Done",
      taskIds: [],
    },
    1: {
      id: 1,
      title: "Working",
      taskIds: [],
    },
    2: {
      id: 2,
      title: "Waiting",
      taskIds: [],
    },
    3: {
      id: 3,
      title: "Backlog (Soon)",
      taskIds: [],
    },
    4: {
      id: 4,
      title: "Backlog (Later)",
      taskIds: [],
    },
  },
  boards: {
    0: {
      columnIds: [0, 1, 2, 3, 4],
      title: "New Board",
      color: "#D1C4E9",
    },
  },
  ui: {
    editTitle: false,
    editColumn: null,
    edit: [null, null],
    nextId: 36,
    colorPicker: {
      x: 0,
      y: 0,
      hex: "#fff",
      visible: false,
    },
    tagColors: {},
  },
};

function BoardsPage() {
  const params = useParams();
  let [data, setData] = useState(defaultData);

  useEffect(() => {
    const es = new EventSource(`/api/boards/${params.key}`);
    es.onmessage = (event) => {
      // Parse data
      data = JSON.parse(event.data);

      // Set default fields if missing
      if (data.tasks === undefined) data.tasks = defaultData.tasks;
      if (data.columns === undefined) data.columns = defaultData.columns;
      if (data.boards === undefined) data.boards = defaultData.boards;
      if (data.ui === undefined) data.ui = defaultData.ui;

      // Fix duplicate taskIds
      for (const columnId in data.boards[0].columnIds) {
        const taskIds = data.columns[columnId].taskIds;
        const newTaskIds = taskIds.filter(
          (item, index) => taskIds.indexOf(item) === index
        );
        data.columns[columnId] = {
          ...data.columns[columnId],
          taskIds: newTaskIds,
        };
      }
      setData(data);
    };
  }, []);

  const onDragStart = () => {
    onTaskSubmit();
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const srcColumnId = parseInt(result.source.droppableId);
    const dstColumnId = parseInt(result.destination.droppableId);
    const taskId = parseInt(result.draggableId);

    // Delete item from source
    const newSrcTaskIds = Array.from(data.columns[srcColumnId].taskIds);
    newSrcTaskIds.splice(result.source.index, 1);
    let newData = {
      ...data,
      columns: {
        ...data.columns,
        [srcColumnId]: {
          ...data.columns[srcColumnId],
          taskIds: newSrcTaskIds,
        },
      },
    };

    // Insert item at destination
    const newDstTaskIds = Array.from(newData.columns[dstColumnId].taskIds);
    newDstTaskIds.splice(result.destination.index, 0, taskId);
    newData = {
      ...newData,
      columns: {
        ...newData.columns,
        [dstColumnId]: {
          ...newData.columns[dstColumnId],
          taskIds: newDstTaskIds,
        },
      },
    };

    setData(newData);
    save(newData);
  };

  const onTaskCreate = (columnId) => {
    setData({
      ...data,
      tasks: {
        ...data.tasks,
        [data.ui.nextId]: {
          id: data.ui.nextId,
          title: "",
        },
      },
      columns: {
        ...data.columns,
        [columnId]: {
          ...data.columns[columnId],
          taskIds: [...data.columns[columnId].taskIds, data.ui.nextId],
        },
      },
      ui: {
        ...data.ui,
        nextId: data.ui.nextId + 1,
        edit: [columnId, data.ui.nextId],
      },
    });
  };

  const onTaskClick = (event, columnId, taskId) => {
    setData({
      ...data,
      ui: {
        ...data.ui,
        edit: [columnId, taskId],
        colorPicker: {
          ...data.ui.colorPicker,
          visible: false,
        },
      },
    });
  };

  const onTaskChange = (event, taskId) => {
    setData({
      ...data,
      tasks: {
        ...data.tasks,
        [taskId]: {
          ...data.tasks[taskId],
          title: event.target.value,
        },
      },
    });
  };

  const onTaskSubmit = (event) => {
    event?.preventDefault();

    // Delete empty tasks
    let newData = data;
    for (const columnId in Object.keys(data.columns)) {
      newData = {
        ...newData,
        columns: {
          ...newData.columns,
          [columnId]: {
            ...newData.columns[columnId],
            taskIds: newData.columns[columnId].taskIds.filter((taskId) => {
              return newData.tasks[taskId].title.length > 0;
            }),
          },
        },
      };
    }

    // Exit edit mode
    newData = {
      ...newData,
      ui: {
        ...data.ui,
        edit: [null, null],
      },
    };

    setData(newData);
    save(newData);
  };

  const onTaskPaste = (event, columnId, taskId) => {
    const pastedText = event.clipboardData.getData("text");
    if (!pastedText.includes("\n")) return;

    event?.preventDefault();
    const tasks = pastedText.split("\n");
    let newData = data;
    tasks.forEach((task) => {
      newData = {
        ...newData,
        tasks: {
          ...newData.tasks,
          [newData.ui.nextId]: {
            id: newData.ui.nextId,
            title: task,
          },
        },
        columns: {
          ...newData.columns,
          [columnId]: {
            ...newData.columns[columnId],
            taskIds: [...newData.columns[columnId].taskIds, newData.ui.nextId],
          },
        },
        ui: {
          ...newData.ui,
          nextId: newData.ui.nextId + 1,
          edit: [null, null],
        },
      };
    });

    data = newData;
    onTaskSubmit();
  };

  const save = (newData) => {
    fetch(`/api/boards/${params.key}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    });
  };

  const onTitleClick = () => {
    setData({
      ...data,
      ui: {
        ...data.ui,
        editTitle: true,
        colorPicker: {
          ...data.ui.colorPicker,
          visible: false,
        },
      },
    });
  };

  const onTitleChange = (event) => {
    const newData = {
      ...data,
      boards: {
        ...data.boards,
        0: {
          ...data.boards[0],
          title: event.target.value,
        },
      },
    };
    setData(newData);
  };

  const onTitleSubmit = (event) => {
    event?.preventDefault();
    const newData = {
      ...data,
      ui: {
        ...data.ui,
        editTitle: false,
      },
    };

    setData(newData);
    save(newData);
  };

  const showColorPicker = (event, tag) => {
    setData({
      ...data,
      ui: {
        ...data.ui,
        edit: [null, null],
        editColumn: null,
        editTitle: false,
        colorPicker: {
          ...data.ui.colorPicker,
          x: event.pageX,
          y: event.pageY,
          tag: tag,
          color: data.ui.tagColors[tag],
          visible: true,
        },
      },
    });
    event.stopPropagation();
  };

  const chooseColor = (newColor) => {
    const newData = {
      ...data,
      ui: {
        ...data.ui,
        colorPicker: {
          ...data.ui.colorPicker,
          visible: false,
        },
        tagColors: {
          ...data.ui.tagColors,
          [data.ui.colorPicker.tag]: newColor.hex,
        },
      },
    };
    setData(newData);
    save(newData);
  };

  const formatTask = (task) => {
    const segments = task.split(": ");
    task = segments[segments.length - 1];

    return (
      <span className="task">
        {segments.slice(0, -1).map((s) => (
          <span
            className="tag"
            style={{ backgroundColor: data.ui.tagColors[s.trim()] }}
            onClick={(e) => showColorPicker(e, s.trim())}
          >
            {s.trim()}
          </span>
        ))}
        {task.split(/(\s+)/).map((word) => {
          if (/^#\w/.test(word)) {
            return (
              <span
                className="hash"
                style={{ backgroundColor: data.ui.tagColors[word.slice(1)] }}
                onClick={(e) => showColorPicker(e, word.slice(1))}
              >
                {word.slice(1)}
              </span>
            );
          }
          return word;
        })}
      </span>
    );
  };

  const onColumnSort = (columnId) => {
    const newTaskIds = Array.from(data.columns[columnId].taskIds);
    newTaskIds.sort((a, b) => {
      const titleA = data.tasks[a].title.toLowerCase();
      const titleB = data.tasks[b].title.toLowerCase();
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [columnId]: {
          ...data.columns[columnId],
          taskIds: newTaskIds,
        },
      },
    };
    setData(newData);
    save(newData);
  };

  useEffect(() => {
    document.title = `${data.boards[0].title} - Kanban`;
  }, [data.boards[0].title]);

  const onColumnClick = (columnId) => {
    data = {
      ...data,
      ui: {
        ...data.ui,
        editColumn: columnId,
        colorPicker: {
          ...data.ui.colorPicker,
          visible: false,
        },
      },
    };
    setData(data);
  };

  const onColumnChange = (event, columnId) => {
    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [columnId]: {
          ...data.columns[columnId],
          title: event.target.value,
        },
      },
    };
    setData(newData);
  };

  const onColumnSubmit = (event) => {
    event?.preventDefault();
    data = {
      ...data,
      ui: {
        ...data.ui,
        editColumn: null,
      },
    };
    setData(data);
    save(data);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {data.ui.editTitle ? (
        <div className="header">
          <form onSubmit={onTitleSubmit}>
            <input
              autoFocus
              value={data.boards[0].title}
              onChange={(event) => onTitleChange(event)}
              onBlur={onTitleSubmit}
            ></input>
          </form>
        </div>
      ) : (
        <div className="header" onClick={onTitleClick}>
          <h1>{data.boards[0].title}</h1>
        </div>
      )}
      <div className="board">
        {data.boards[0].columnIds.map((columnId) => (
          <div key={columnId} className="column">
            {data.ui.editColumn == columnId ? (
              <form onSubmit={(event) => onColumnSubmit(event, columnId)}>
                <input
                  autoFocus
                  className="columnTitle"
                  value={data.columns[columnId].title}
                  onChange={(event) => onColumnChange(event, columnId)}
                  onBlur={(event) => onColumnSubmit(event, columnId)}
                ></input>
              </form>
            ) : (
              <h2 onClick={() => onColumnClick(columnId)}>
                {data.columns[columnId].title}
              </h2>
            )}
            <h3>{data.columns[columnId].taskIds.length} tasks</h3>
            <Droppable droppableId={columnId.toString()}>
              {(droppableProvided, droppableSnapshot) => (
                <ul
                  ref={droppableProvided.innerRef}
                  className={
                    droppableSnapshot.isDraggingOver ? "highlight" : ""
                  }
                  {...droppableProvided.droppableProps}
                >
                  {data.columns[columnId].taskIds.map((taskId, taskIndex) =>
                    columnId === data.ui.edit[0] &&
                    taskId === data.ui.edit[1] ? (
                      <div className="wrapper">
                        <li key={taskId.toString()} className="edit">
                          <form
                            onSubmit={(event) =>
                              onTaskSubmit(event, columnId, taskId)
                            }
                          >
                            <input
                              autoFocus
                              value={data.tasks[taskId].title}
                              onChange={(event) => onTaskChange(event, taskId)}
                              onPaste={(event) =>
                                onTaskPaste(event, columnId, taskId)
                              }
                              onBlur={(event) =>
                                onTaskSubmit(event, columnId, taskId)
                              }
                            ></input>
                          </form>
                        </li>
                      </div>
                    ) : (
                      <Draggable
                        key={taskId.toString()}
                        draggableId={taskId.toString()}
                        index={taskIndex}
                      >
                        {(draggableProvided) => (
                          <div
                            className="wrapper"
                            onClick={(event) =>
                              onTaskClick(event, columnId, taskId)
                            }
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                          >
                            <li>{formatTask(data.tasks[taskId].title)}</li>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {droppableProvided.placeholder}
                  <div className="buttonPane">
                    <button
                      className="action"
                      title="New task"
                      onClick={() => onTaskCreate(columnId)}
                    >
                      <i className="fa fa-solid fa-plus" />
                    </button>
                    <button
                      className="action"
                      title="Sort"
                      onClick={() => onColumnSort(columnId)}
                    >
                      <i
                        className="fa fa-sort-amount-asc"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </ul>
              )}
            </Droppable>
          </div>
        ))}
      </div>
      {data.ui.colorPicker.visible && (
        <Github
          style={{
            position: "absolute",
            left: `${data.ui.colorPicker.x}px`,
            top: `${data.ui.colorPicker.y}px`,
          }}
          color={data.ui.colorPicker.color}
          onChange={(color) => {
            chooseColor(color);
          }}
          colors={material_palette_300}
        />
      )}
    </DragDropContext>
  );
}

export default BoardsPage;
